<template>

  <template v-if="isUserDataLoading || isBootstrapDataLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <template v-if="paginationList.totalItems === 0 && !paginationList.hasFiltersOrQueryApplied">
      <EmptyNotice>
        <template #image>
          <img src="@/assets/images/telescope.png" />
        </template>
        <template #title>
          There's nothing to see here.
        </template>
        <template #message>
          Once your Patches have any activity, you'll see that here.
        </template>
      </EmptyNotice>
    </template>

    <template v-else>

      <PaginationFilters :filterFormName="filterFormName" :paginationListName="paginationListName" />

      <template v-if="paginationList.totalItems === 0">
        <div class="relative">
          <LoadingOverlay type="light" v-if="paginationList.isLoading" />
          <EmptyNotice :showActionButton="false" :showPatchTemplates="false">
            <template #image>
              <img src="@/assets/images/astronaut-sad.png" />
            </template>
            <template #title>
              Sorry, nothing matches your search.
            </template>
            <template #message>
              We couldn't find any Patch Events matching your search. Please try again.
            </template>
            <template #extra>
              <button class="link mt-4" @click="resetFilterForm">Clear search</button>
            </template>
          </EmptyNotice>
        </div>
      </template>

      <template v-else>
        <div class="table-container">
          <LoadingOverlay type="light" v-if="paginationList.isLoading" />
          <div class="table">
            <div class="table-header table-row">
              <div class="small"></div>
              <div>PATCH</div>
              <div class="flex-grow">NAME</div>
              <div>STATUS</div>
              <div class="timestamp">TIMESTAMP</div>
            </div>
            <PatchHistoryTableRow
              :key="patchEvent.id"
              :patchEvent="patchEvent"
              @toggle="detailsToggled"
              v-for="patchEvent in paginationList.currentPage"
            />
          </div>
        </div>
        <PaginationButtons :paginationListName="paginationListName" />
      </template>

    </template>

  </template>

</template>

<script>

  import { ref } from 'vue'
  import { mapState } from 'vuex'

  import usePaginationPolling from '@/composables/usePaginationPolling'
  import usePaginationFilters from '@/composables/usePaginationFilters'

  import EmptyNotice from '@/components/page/EmptyNotice.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import PaginationFilters from '@/components/utils/PaginationFilters.vue'
  import PaginationButtons from '@/components/utils/PaginationButtons.vue'
  import PatchHistoryTableRow from '@/components/tables/PatchHistoryTableRow.vue'

  export default {
    components: {
      LoadingIcon,
      EmptyNotice,
      LoadingOverlay,
      PaginationFilters,
      PaginationButtons,
      PatchHistoryTableRow,
    },
    setup() {

      const numDetailsOpen = ref(0)
      const paginationListName = ref('userPatchEvents')
      const filterFormName = ref('patchHistoryFiltersForm')

      const {
        filterForm,
        paginationList,
        resetFilterForm,
        numSelectedFilters,
      } = usePaginationFilters({ filterFormName, paginationListName })

      // @TODO: remove this when socket stuff is available
      const { detailsToggled } = usePaginationPolling({ paginationListName, numDetailsOpen })

      return {
        filterForm,
        paginationList,
        filterFormName,
        detailsToggled,
        resetFilterForm,
        paginationListName,
        numSelectedFilters,
      }

    },
    computed: {
      ...mapState('user', ['isUserDataLoading']),
      ...mapState('app', ['isBootstrapDataLoading']),
    },
    watch: {
      // @NOTE: this route watcher exists so that if the route changes from
      //  /patches/history to /patches/history/:patchEventId the patch event
      //  modal will open (since the logic in the created hook only fires when
      //  the roue initially loads, i.e. when the user is sent to
      //  /patches/history/:patchEventId from a different route or loading that
      //  route first)
      //
      // currently there's no real way to actually navigate from
      //  /patches/history to /patches/history/:patchEventId, but it's useful
      //  for testing and just to be future-proof
      $route(to) {
        if (to.name === 'PatchHistory' && to.params.patchEventId) {
          this.openPatchEventModal(to.params.patchEventId)
        }
      },
    },
    created() {

      this.$store.dispatch('pagination/RESET_PAGINATION', { listName: this.paginationListName })

      if (this.$route.params.patchEventId) {
        this.openPatchEventModal(this.$route.params.patchEventId)
      }

    },
    methods: {
      openPatchEventModal(patchEventId) {
        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'PatchEventModal',
          onClose: () => {
            this.$router.replace({ params: { patchEventId: null } })
          },
          props: {
            patchEventId,
          },
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

</style>

<template>

  <div class="table-row clickable" :class="{ 'is-open': showDetails }" @click="toggleDetails">
    <div class="small">
      <button
        @click.stop="toggleDetails"
        class="toggle-details-button small ghost"
      >
        <ChevronIcon />
      </button>
    </div>
    <div class="icons">
      <img :src="patchEvent.iconUrl" />
      <ArrowIcon class="arrow-icon" />
      <img :src="patchEvent.patch.action.iconUrl" />
    </div>
    <div class="flex-grow">
      {{ patchEvent.patch.name }}
    </div>
    <div>
      <span class="status-indicator" :class="[patchEvent.status]" />
      {{ titleCase(patchEvent.status) }}
    </div>
    <div class="timestamp">
      {{ formatTimestamp(patchEvent.insertedAt) }}
    </div>
  </div>
  <PatchEventDetails
    :show="showDetails"
    location="patch-history"
    :patchEvent="patchEvent"
  />

</template>

<script>

  import useFilters from '@/composables/useFilters'

  import ArrowIcon from '@/assets/icons/arrow.svg'
  import ChevronIcon from '@/assets/icons/chevron.svg'

  import PatchEventDetails from '@/components/tables/PatchEventDetails.vue'

  export default {
    emits: ['toggle'],
    props: {
      patchEvent: {
        type: Object,
        required: true,
      },
    },
    components: {
      ArrowIcon,
      ChevronIcon,
      PatchEventDetails,
    },
    setup(props) {
      const { formatTimestamp, titleCase } = useFilters()
      return { formatTimestamp, titleCase }
    },
    data() {
      return {
        showDetails: false,
      }
    },
    methods: {
      toggleDetails() {
        this.showDetails = !this.showDetails
        this.$emit('toggle', this.showDetails)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .status-indicator
    &.failure
      @apply bg-danger-700

    &.pending
      @apply bg-warning-500

</style>
